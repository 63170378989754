<!-- 会员页 -->
<template>
    <div style="height:100%;background: #fff;">
        <van-nav-bar title="会员中心" />
        <div style="width:90%;margin:0 auto">
          <van-divider :style="{ color:'#ccc', borderColor:'#ac931b', padding:'0 16px',fontWeight:'bold' }">{{memeberInfo.username }}</van-divider>
          <div class="vant-item">
              <div class="f_content">
                  <img src="../../assets/imgs/logo.jpg" alt="" style="width:150px;margin-left:20px">
                 
                  <p style="padding:30px;color:#969799">甄煮时光 | 精选燕窝</p>
              </div>
              <div  id='qrcode' @click="codeBind"></div>
          </div>
          <div class="vant-item vant-item2">
              <div class="li"  v-if="!islogon">
                  <p>余额</p>
                  <span class="publiccolor">**元</span>
              </div>
              <div class="li" @click="certificateBind" v-if="!islogon">
                  <p>卡类型</p>
                  <span class="publiccolor">**</span>
              </div>
              <div class="li"  v-if="islogon">
                  <p>余额</p>
                  <span class="publiccolor">{{memberCardRespVo.availableBalance}}元</span>
              </div>
              <!-- <div class="li" @click="certificateBind" v-if="islogon"> -->
              <div class="li" v-if="islogon">
                  <p>{{memberCardRespVo.memberType==1?'储值卡':'提货卡'}}</p>
                  <span class="publiccolor">{{memberCardRespVo.availableCouponCount}}</span>
              </div>
          </div>
          <div class="vant-item vant-item3">
              <van-cell title="开卡信息" is-link @click="codeBind"/>
              <van-cell title="消费历史" is-link @click="costBind"/>
          </div>
          <div class="vant-item vant-item3">
              <van-cell title="设置" is-link @click="setupBind"/>
          </div>
      </div>
    </div>
</template>
<script>
import QRCode from "qrcodejs2";
import {getuserInfo}  from "@/api/base";
import { Notify } from 'vant';
export default {
  name: 'card',
  data(){
    return {
        islogon:false,
        memeberInfo:{
          username:'',//用户账号
        },//用户信息
        memberCardRespVo:{
          availableBalance:'',//余额
          availableCouponCount:'',//券
          publicQrCode:'',//二维码
        }//会员卡信息
    }
  },
  mounted(){
    var path = window.location.pathname;
    var segments = path.split('/'); 
    let username=segments[segments.length - 1];
    if(!sessionStorage.getItem("Authorization") || username != sessionStorage.getItem("username" )){
        this.islogon=false;
        this.qrCode(path);
        this.memeberInfo.username =  username ;
        sessionStorage.setItem( 'username',username )
        return;
    }
    this.islogon=true;
    this .getuserinfoFun();
  },
  methods:{
    getuserinfoFun(){
      getuserInfo().then((res)=>{
        if(res.code==0){  
          this.memeberInfo=res.data;
          this.memberCardRespVo=res.data.memberCardRespVo;
          sessionStorage.setItem('username',this.memeberInfo.username)
          if(this.memberCardRespVo.activationQrCode){
            this.qrCode(this.memberCardRespVo.activationQrCode);
          }
        }else{
          Notify({ type: 'warning', message: res.msg });
        }
      },(err)=>{
        Notify({ type: 'warning', message: '网络错误'});
      })
    },
    onClickLeft(){
      this.$router.go(-1)
    },
    codeBind(){
      this.$router.push({ path: `/mine/mycode/${this.memeberInfo.username}`});
    },
    setupBind(){
      this.$router.push({name:'setup'})
    },
    costBind(){
      this.$router.push({ path: '/mine/mycostrecord'});
    },
    certificateBind(){
      this.$router.push({ path: '/mine/mycertificate'});
    },
    qrCode(url){
        document.getElementById('qrcode').innerHTML = '';
        var qrcode=new QRCode('qrcode',{
            text:url,//要生成二维码的链接
            margin:5,
            quality:.5,
            width:70,//二维码的宽度
            height:70,//二维码的高度
            colorDark:'#000000',//前景色
            colorLight:'#ffffff',//背景色
            correctLevel:QRCode.CorrectLevel.H  //纠错等级
          })
          qrcode.clear() //清除二维码 
          qrcode.makeCode(url) //生成另一个新的二维码  
     },
  }
}
</script>
<style lang="scss" scoped>
 #qrcode{
    position: absolute;
    top:50px;
    right:30px;
    width:70px;
    height:70px;
 }
 .vant-item{
    position: relative;
    border:1px solid #ac931b;
    border-radius:10px;
    margin-top:20px;
    padding:10px 0;
 }
 .vant-item2{
  display: flex;
  .li{
      flex:1;
      padding:10px;
      text-align: center;
      box-sizing: border-box;
      color:#969799;
      p{
        line-height: 50px;
      }
    }
    .li:nth-child(1){
      border-right:1px solid #ac931b
    }
 }
 .vant-item3 span{
  color:#969799
 }
 .van-cell::after {
    border-bottom: 1px solid #ac931b;
}
</style>
